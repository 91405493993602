import React from "react";

const ContactInfo = () => {
  return (
    <div className="contactInfo container">
      <div className="row">
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>Call Us 24x7</h5>
            <p>+27 78 776 0294</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h5>Collection Point</h5>
            <span>The watershed</span>
            <span>Beauty box</span>
            <span>V&A waterfront</span>
            <span>Cape Town</span>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-fax"></i>
            </div>
            <h5>Email Us 24x7</h5>
            <p>info@afrikare.co.za</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
