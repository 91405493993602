import image1 from "../images/cat1.jpg";
import image2 from "../images/cat2.jpg";
import image3 from "../images/cat3.jpg";



const categoryData = [
    
    {
        id: 1,
        image: image2 ,
        title: "Hair",
        cat: "hairproducts"
    },
    {
        id: 2,
        image: image3,
        title: "Bath and Body",
        cat: "bodybath"
    },
    {
        id: 3,
        image: image1 ,
        title: "Home",
        cat: "homecare"
    },
]

export default categoryData